<template>
  <v-fade-transition mode="out-in">
    <v-sheet key="loading" v-if="loading" dark  class="fill-height d-flex align-center justify-center" />


    <div key="page"  v-else>
      <viewPage :page="pageAlias" :news-alias="newsAlias" :course="course" :document-check="documentCheck" />
      <login-side-bar/>
      <ws-dialog
          :value="$store.state.auth.loggedIn && !$store.state.auth.user.phone"
          @save="addPhoneNumber"
          :title="$t('EnterPhoneNumber')"
          persistent
      >

      <h4 :style="`color : ${wsACCENT}`" class="mb-10 mt-5 text-center">
        {{ $t('NoPhoneDashboardWarning') }}
      </h4>

      <ws-phone-input
          :label="$t('Phone')"
          v-model="phoneNumber"
          :error-messages="phoneError"
          :error="!!phoneError"
          @input="phoneError = false"
          :hide-details="false"
      />
    </ws-dialog>
  </div>

  </v-fade-transition>

</template>

<script>
import viewPage from "@/components/AvalonEditor/View/viewPage";
import loginSideBar from "@/components/auth/loginSideBar";
import {mapActions, mapMutations} from "vuex";
import referral from "@/mixins/referral";

import defaultConfig from "@/assets/json/avalon/defaultConfig.json";

export default {
  name: "BusinessPublicPage",
  components : {
    viewPage,
    loginSideBar
  },
  mixins : [referral],
  props : {
    pageAlias : {
      type : String
    },
    newsAlias : {
      type : String
    },
    alias : {
      type : String
    },
    course : {
      type : String
    },
    documentCheck : {
      type :Boolean,
      default : false
    }

  },
  data() {
    return {
      loading : true,
      phoneNumber : null,
      phoneError : null
    }
  },
  methods : {
    ...mapActions('avalon',['GET_BUSINESS_AVALON']),
    ...mapActions('auth', ['ADD_PHONE_NUMBER' , 'CHECK_EMAIL']),
    ...mapMutations('auth',['LOGOUT']),
    async addPhoneNumber() {
      if ( !this.phoneNumber) {
        this.phoneError = this.$t('EnterPhone')
        return this.notify(this.$t('EnterPhone') , 'warning')
      }

      if ( !await this.CHECK_EMAIL({ type : 'phone', phone : this.phoneNumber }) ) {
        this.phoneError = this.$t("UserAlreadyExists");
        return this.notify(this.$t('UserAlreadyExists') , 'warning')
      }

      let result = await this.ADD_PHONE_NUMBER({ phone : this.phoneNumber })
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.$store.state.auth.user.phone = result.phone
    },
    async getSiteConfig() {
     let result = await this.GET_BUSINESS_AVALON({ business : this.$store.state.domain ? location.host : this.alias , public : true})
      if ( !result ) {
        this.loading = false
      }
      this.loading = false
      this.checkBusinessReferral()


        if (result.fPixelId) {
          this.fPixelInit( result.fPixelId)
        }
      this.LOAD_FONTS()
    }
  },
  beforeMount() {
    this.$store.state.avalon.editorMode = false
    this.$store.state.avalon.sideMenu = false
    this.$store.state.avalon.defaultConfig = defaultConfig
  },
  mounted() {
    this.getSiteConfig()
  }
}
</script>

<style scoped>

</style>